import React, { Component } from "react";
import Nav from "../../Nav";
import MetaTags from "react-meta-tags";
import "../Industries.css";
import "../../Table.css";
import Container from "../../Container";
import { NavLink } from "react-router-dom";
import Footer from "../../Footer";
import ButtonLearnMore from "../../ButtonLearnMore";

class Healthcare extends Component {
  render() {
    return (
      <Container>
        <MetaTags>
          <meta
            id="meta-description"
            name="description"
            content=" TrustThink has worked with industry organizations to develop tabletop
                exercises and guides for deployment of robotic assisted surgical
                systems. "
          ></meta>
        </MetaTags>
        <Nav />
        <div id="cybersol_div">
          
          <div class="stretch">
            
            <div class="container content center padding-64 tester">
              <h2 class="wide">Industries</h2>
              <h1 class="lead highfont">
              TrustThink collaborates with leaders in the transportation, healthcare, and defense sectors to navigate the complex landscape of 
              modern technology, ensuring security and innovation go hand in hand.
              </h1>

              <p class="opacity">
                <i></i>
              </p>

            </div>
            
          </div>
        </div>

        <body>
          
          <div class="grid10"> 
              <div class="imgtext contain2">
                  <h2 class="title">
                    Securing Healthcare Environments
                  </h2>
                  <img class="healthcare_img center" alt="Robotics-Assisted Medical Tools">
                  </img>
                  <br></br>
                  <p>
                  As healthcare technology advances, medical devices are increasingly connecting to hospital infrastructures and cloud services via cellular, Wi-Fi, and Bluetooth. These advancements,
                  while beneficial, introduce potential vulnerabilities that can compromise patient safety and data security. 
                  TrustThink specializes in fortifying these connections against cyber threats, 
                  ensuring devices comply with healthcare regulations. We assist medical device manufacturers through the FDA 510(k) cybersecurity documentation process and support the secure integration of
                    IoT into healthcare environments, focusing on robust security architectures to protect against evolving cyber threats. 
                  </p>     
                 
            
              
            
                 
                      
                 
             
              </div>
              
           
    

          </div>
          <div class="grid3">
            
            <p>
                    
                    <h5> Work with TrustThink to: </h5>
                  <li> Navigate the FDA 510(k) cybersecurity documentation process for medical devices, ensuring compliance and enhanced security. </li>

                  <li> Develop and implement secure integration strategies for IoT in healthcare environments, safeguarding patient data and system integrity. </li>

                  <li> Establish security configurations and robust defenses for connected medical devices, including those interfacing with cloud services. </li>

                  <li> Assist Hospitals (HDOs) in designing secure medical device architectures tailored to their specific needs and compliance requirements. </li>

                  <li> Partner with us to ensure your healthcare technologies not only meet current security standards but are also prepared for future advancements and regulations. </li>
                    </p>
                  <p class="flex flex-col items-center bg-gray-800 border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl">
                        <div class="flex flex-col justify-between p-4 leading-normal">
                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white">Ensure compliance with FDA 510K requirements with our documentation and drawing package.</h5>
                            <p class="mb-3 font-normal text-gray-400">Contact us today: <strong>solutions@trustthink.net</strong></p>
                            <ButtonLearnMore buttonLink="/cybersolutions/medical"/>
                        </div>
                    </p>
        
                    
            </div>

          

          <div class="grid10">
           {/* Back button */}
           <NavLink to="/industries">
              <button type="button" class="back_btn">
                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>
          </div>
          

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default Healthcare;

{
  /*

        <ul>
          <li>
            <a
              href="https://www.uclahealth.org/medical-services/robotic-surgery/what-robotic-surgery"
              class="learnLinks"
            >
                RAS (Robot Assisted Surgery System)</a>
          </li>
    
        </ul>


        <p>
 
        </p>


*/
}
