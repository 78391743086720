import React, { Component } from "react";

import "../Table.css";
import Container from "../Container";
import medicalImage from '../../img/medicalequipment.webp';
import Footer from "../Footer";
import { NavLink } from "react-router-dom";
import Nav from "../Nav";
class CyberSolMedical extends Component {
  render() {
    return (
      <Container>
        <Nav/>
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">TrustThink Solutions</h2>
              <p class="opacity">
                <i></i>
              </p>
              <p class="lead highfont">
              Explore our tailored solutions that empower organizations to navigate technological changes and enhance security in a dynamic world.
              </p>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

            <div class="title">
               <h2>Medical Device Cybersecurity Documentation Package for FDA 510k Clearance</h2>
            </div>
            <img class="images" src={medicalImage} alt="Electronic medical devices" style= {{height: 200, width: 300}}/>


            <div class="imgtext">
              <p class="">
              Navigate the complex landscape of FDA 510(k) clearance with confidence through our Comprehensive Cybersecurity Compliance Solutions. Specializing in the intricacies of medical device cybersecurity, 
              TrustThink provides a tailored documentation and drawing package designed to meet the stringent requirements of the FDA. From standalone medical devices to advanced cloud-connected diagnostic systems,
               our service covers all necessary cybersecurity documentation, including risk assessments, threat modeling, and mitigation strategies, ensuring your device's security posture aligns with regulatory standards. 
               Benefit from our deep expertise in cybersecurity as we streamline the clearance process, safeguard patient data, and protect device integrity against emerging threats. 
              TrustThink's dedicated approach not only positions your device for successful clearance but also fortifies your commitment to patient safety and data security in the healthcare landscape.
              </p>
            </div>
            <div class="bottom">

              <a href="mailto:solutions@trustthink.net">
                          <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
              </a>
              
            </div>

             {/* Back button */}
             <NavLink to="/cybersolutions/overview">
              <button type="button" class="back_btn">
                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>
            
          </div>

          

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        
        <Footer/>
      </Container>
    );
  }
}

export default CyberSolMedical;
